.callPage {
    /*noinspection CssUnresolvedCustomProperty*/
    background-color: var(--tg-theme-bg-color);

    display: flex;
    flex-direction: column;
    height: 100%;
    width: 85%;

    margin-left: auto;
    margin-right: auto;
    /*justify-content: space-between;*/
}

.textHallo {
    font-family: 'Roboto', serif;
    font-size: 26px;
    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-text-color);

    margin-top: 25px;
}

.statusBlock {
    margin-top: 15px;
    /*margin-left: 25px;*/
    display: flex;
    flex-direction: column;
}

.blockChooseStatus {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.textStatus {
    /* Твой статус на базе */
    margin-bottom: 12px;

    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;

    display: flex;
    align-items: center;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-hint-color);

}

.buttonBlock {
    display: flex;
    justify-content: end;
    height: 13.7%;
    width: 100%;
}

.mainInfoBlock {
    display: flex;
    flex-direction: column;
    height: 78.87%;
    width: 100%;
}

.participantBlock {
    display: flex;
    flex-direction: column;

    height: 60%;
}

.participantsTitleText {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;

    display: flex;
    align-items: center;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-hint-color);

    margin-bottom: 15px;
    margin-top: 25px;
}

.participantsTitleText:hover {
    cursor: pointer;
}