.background {
    display: flex;

    align-items: center;
    justify-content: center;

    margin-right: auto;
    margin-left: auto;

    /*noinspection CssUnresolvedCustomProperty*/
    background-color: var(--tg-theme-header-bg-color);

    border-radius: 13px;

    width: 340px;
    min-height: 54px;
}

.text {
    display: flex;

    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    text-align: center;

    color: #F6F6F6;
}