.invitePage {
    /*noinspection CssUnresolvedCustomProperty*/
    background-color: var(--tg-theme-bg-color);

    display: flex;
    flex-direction: column;
    height: 100%;

    width: 90%;

    margin-right: auto;
    margin-left: auto;

    justify-content: space-between;
}

.textBlock {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    margin-right: auto;
    margin-left: auto;
}

.initializerName {
    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-link-color);

    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;

    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;
}

.inviteText {
    /* зовет на перекур */

    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-text-color);
}

.answerButtonsBlock {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.remoteBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.remoteBlockText {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    display: flex;
    align-items: center;
    text-align: center;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-hint-color);
}