.inOfficeButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 54px;

    background: #41CA80;
    border-radius: 14px;
}

.inOfficeButton:hover {
    cursor: pointer;
}

.buttonText {
    display: flex;

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    align-items: center;
    text-align: center;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-text-color);

    user-select: none;
}