.pageBackground {
    /*noinspection CssUnresolvedCustomProperty*/
    background-color: var(--tg-theme-bg-color);

    display: flex;
    flex-direction: column;
    height: 100%;

    width: 90%;

    margin-right: auto;
    margin-left: auto;

    gap: 34px;
}

.text {
    /* Ждем остальных... */
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;

    margin-left: auto;
    margin-right: auto;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-button-text-color);
}