.inputBackground {
    /* Frame 1 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 24px;
    gap: 10px;

    width: 342px;
    height: 52px;

    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 12px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    font-family: 'Roboto', serif;

    font-size: 20px;
}