.participantsListBackground {
    background: rgba(255, 255, 255, 0.12);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    height: 100%;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    padding: 12px;
    /*padding-right: 12px;*/
    gap: 4px;

    overflow-y: scroll;

    /*width: 100%;*/
    /*height: 100%;*/
}