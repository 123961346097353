.pageBackground {
    /*noinspection CssUnresolvedCustomProperty*/
    background-color: var(--tg-theme-bg-color);

    display: flex;
    flex-direction: column;
    height: 100%;

    width: 90%;

    margin-right: auto;
    margin-left: auto;

    justify-content: space-between;
}

.textBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.textAboutCancel {
    /* Перекур отменили 😢 */
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    /* or 100% */
    display: flex;
    align-items: center;
    text-align: center;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-button-text-color);
}

.textAboutNotExist {
    /* Или его никогда не существовало... */

    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    /* or 160% */
    display: flex;
    align-items: center;
    text-align: center;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-hint-color);
}