.participantBackground {
    background: rgba(255, 255, 255, 0.12);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
    width: 86%;

    padding-left: 7%;
    padding-right: 7%;
}

.participantName {

    display: flex;

    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    margin-left: auto;
    margin-right: auto;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-text-color);
}