.comingButtonBackground {
    /* Rectangle 3 */

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 54px;

    background: #41CA80;
    border-radius: 14px;
}

.comingButtonBackground:hover {
    cursor: pointer;
}

.buttonText {
    /* Иду! */
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #F0EFEF;
}