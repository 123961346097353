.loader {
    display: flex;

    animation: loader-rotation 1.2s linear infinite;;
}

@keyframes loader-rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}