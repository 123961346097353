.callButton {
    /*width: 278px;*/
    width: 100%;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: auto;
    margin-right: auto;

    /*noinspection CssUnresolvedCustomProperty*/
    background-color: var(--tg-theme-button-color);

    border-radius: 14px;

    cursor: pointer;
}

.callButton:active {
    box-shadow: inset 0px 3px 10px rgba(0, 0, 0, 0.25);
}

.buttonText {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    font-size: 24px;
    font-family: 'Roboto', serif;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-button-text-color);

    user-select: none;
}