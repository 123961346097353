.page {
    /*noinspection CssUnresolvedCustomProperty*/
    background-color: var(--tg-theme-bg-color);

    display: flex;
    flex-direction: column;
    height: 100%;

    width: 90%;

    margin-right: auto;
    margin-left: auto;

    justify-content: space-between;
}

.inputBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.inputLegend {
    height: 56px;

    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* or 140% */
    display: flex;
    text-align: left;
    align-items: center;
    justify-items: flex-start;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-text-color);

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}