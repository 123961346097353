.bodyStatus {
    /* Кнопка вызова */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*padding: 12px 16px;*/
    gap: 8px;

    width: 111px;
    height: 48px;

    background: #5BD468;
    border: 2px solid #00A407;
    border-radius: 12px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    user-select: none;
}

.bodyInactiveStatus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*padding: 12px 16px;*/
    gap: 8px;

    width: 111px;
    height: 48px;

    /*noinspection CssUnresolvedCustomProperty*/
    background: var(--tg-theme-secondary-bg-color);
    border-radius: 12px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    user-select: none;
    cursor: pointer;
}

.statusText {
    /* Офис */
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    display: flex;
    align-items: center;
    text-align: center;

    color: #F0EFEF;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.statusInactiveText {
    /* Офис */
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    display: flex;
    align-items: center;
    text-align: center;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-text-color);

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}