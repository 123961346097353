.remotePage {
/*noinspection CssUnresolvedCustomProperty*/
    background-color: var(--tg-theme-bg-color);

    display: flex;
    flex-direction: column;
    height: 100%;

    justify-content: space-between;

    width: 335px;
    margin-left: auto;
    margin-right: auto;
}

.textHallo {
    font-family: 'Roboto', serif;
    font-size: 26px;
    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-text-color);

    margin-top: 25px;
}

.statusBlock {
    margin-top: 15px;
    /*margin-left: 25px;*/
    display: flex;
    flex-direction: column;
}

.blockChooseStatus {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.textStatus {
    /* Твой статус на базе */
    font-family: 'Roboto', serif;
    margin-bottom: 12px;

    font-style: normal;
    font-weight: 400;
    font-size: 22px;

    display: flex;
    align-items: center;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-hint-color);

}

.textBlock {
    display: flex;
    flex-direction: column;

    /*margin-top: 25px;*/
    margin-left: auto;
    margin-right: auto;

    width: 100%;
}

.textFirst {

    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-text-color);
}

.textSecond {
    margin-top: 20px;

    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--tg-theme-text-color);
}

.infoBlock {
    display: flex;
    flex-direction: column;

    width: 100%;
}